<template>
  <v-container>
    <v-row>
      <v-col>
        <historialcliente />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import historialcliente from '@/views/crmpro/historial/historialcliente.vue'
export default {
  components:{
    historialcliente,
  }
}
</script>

<style>

</style>